import React, { useState } from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"
import "lazysizes"
import clsx from "clsx"
import { graphql } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import SEO from "../components/SEO"
import GeneralContact from "../components/contacts/GeneralContact"
import Survey from "../components/contacts/Survey"
import { Location } from "@reach/router"
import RiskCalculator from "../components/form/RiskCalculator"
import MenuItems from "../components/departments/MenuItems"
import SectionTitle from "../components/SectionTitle"
import Breadcrumbs from "../components/Breadcrumbs"
import Accordion from "../components/Accordion"
import MixedCarousel from "../components/carousels/MixedCarousel"
import ContactPopup from "../components/contacts/ContactPopup"

const useStyles = makeStyles((theme) => ({
  video: {
    height: 260,
  },
  survey: {
    height: 2650,
    width: "100%",
  },
  wideContact: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: -theme.spacing(3),
      width: "100vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(2),
      width: "100vw",
    },
  },
}))

const StaticPage = ({
  data: { staticPage },
  pageContext: { locale, slug, breadcrumbs },
  location,
}) => {
  const classes = useStyles()
  const schema = ""
  const ogp = []

  const [contactModalOpen, setContactModalOpen] = useState(false)
  const [selectedContactLocationUid, setSelectedContactLocationUid] = useState(
    "-"
  )

  const onSelectContactLocation = contactLocationUid => {
    setSelectedContactLocationUid(contactLocationUid)
    setContactModalOpen(true)
  }

  const meta = {
    title: staticPage.data.title.text,
    description: staticPage.data.meta_description || "",
    keywords: staticPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  return (
    <>
      <SEO schema={schema} ogp={ogp} meta={meta} locale={locale} />
      <Container maxWidth="lg">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <br />
        <Grid
          container
          spacing={4}
          justifyContent="center"
        >

          <Grid item xs={12} md={8}>
            <SectionTitle component="h1">
              {staticPage.data.title.text}
            </SectionTitle>
            <Grid container>

              {staticPage.data.body1 &&
                staticPage.data.body1.map(slice => (
                  <Grid item xs={12} key={shortid.generate()}>


                    {slice.slice_type === "simple" && (
                      <Typography
                        component="div"
                        dangerouslySetInnerHTML={{
                          __html: slice.primary.content.html,
                        }}
                      />
                    )}

                    {slice.slice_type === "survey" && (
                      <iframe
                        title="Survey"
                        src={slice.primary.survey_url.url}
                        frameBorder="0"
                        className={classes.survey}
                        marginWidth="0"
                        marginHeight="0"
                        scrolling="auto"
                      />
                    )}

                    {slice.slice_type === "form" && (
                      <GeneralContact
                        location={location}
                        locale={locale}
                        formName={slice.primary.name}
                        disclaimerMessage={
                          slice.primary.disclaimer_message.html
                        }
                        sendToCRM={slice.primary.send_to_crm}
                        contactMessage={slice.primary.message_placeholder}
                      />
                    )}

                    {slice.slice_type === "advanced_form" && (
                      <Survey
                        location={location}
                        locale={locale}
                        formName={slice.primary.name}
                        disclaimerMessage={
                          slice.primary.disclaimer_message.html
                        }
                        contactMessage={slice.primary.message_placeholder}
                      />
                    )}

                    {slice.slice_type === "risk_calculator" && (
                      <RiskCalculator
                        location={location}
                        locale={locale}
                      />
                    )}

                    {slice.slice_type === "accordion" && (
                      <div style={{ marginTop: 16 }}>
                        <Accordion items={slice.items} />
                      </div>
                    )}

                    {slice.slice_type === "mixed_carousel" && (
                      <>
                        <Typography
                          component="div"
                          dangerouslySetInnerHTML={{
                            __html: slice.primary.content.html,
                          }}
                        />
                        <MixedCarousel items={slice.items.map(item => item.link.document)} onSelectContactLocation={onSelectContactLocation} />
                      </>
                    )}
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} key={shortid.generate()}>
            <div>
              <Location>
                {locationProps => (
                  <MenuItems locale={locale} slug={slug} {...locationProps} />
                )}
              </Location>
            </div>

            <div className={`${clsx("contactHolder", classes.wideContact)}`}>
              <GeneralContact location={location} locale={locale} />
            </div>

          </Grid>
        </Grid>
        <ContactPopup
          locale={locale}
          location={location}
          open={contactModalOpen}
          handleClose={() => setContactModalOpen(false)}
          selectedContactLocationUid={selectedContactLocationUid}
        />
      </Container>
    </>
  )
}

export default StaticPage

export const query = graphql`
  query staticPageQuery($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    staticPage: prismicStaticPage(uid: { eq: $slug }, lang: { eq: $locale }) {
      data {
        title {
          text
        }
        content {
          html
        }
        body1 {
          ... on PrismicStaticPageDataBody1Simple {
            id
            slice_type
            primary {
              content {
                html
              }
            }
          }
          ... on PrismicStaticPageDataBody1Survey {
            id
            slice_type
            primary {
              survey_url {
                url
              }
            }
          }

          ... on PrismicStaticPageDataBody1Form {
            id
            slice_type
            primary {
              name
              send_to_crm
              disclaimer_message {
                html
              }
              message_placeholder
            }
          }



          ... on PrismicStaticPageDataBody1RiskCalculator {
            id
            slice_type
          }



          ... on PrismicStaticPageDataBody1Accordion {
            id
            slice_type
            items {
              section_content {
                html
              }
              section_title {
                text
              }
            }
          }



          ... on PrismicStaticPageDataBody1Form {
            id
            slice_type
            primary {
              name
              send_to_crm
              disclaimer_message {
                html
              }
              message_placeholder
            }
          }


          ... on PrismicStaticPageDataBody1AdvancedForm {
            id
            slice_type
            primary {
              name
              send_to_crm
              disclaimer_message {
                html
              }
              message_placeholder
            }
          }






          ... on PrismicStaticPageDataBody1MixedCarousel {
            id
            slice_type
            primary {
              content {
                html
                raw
              }
            }
            items {
              link {
                document {
                  ... on PrismicProcedure {
                    id
                    uid
                    type
                    data {
                      categories {
                        category {
                          document {
                            ... on PrismicProcedureCategory {
                              id
                              uid
                              data {
                                icon {
                                  alt
                                  gatsbyImageData(layout: FIXED, width: 150)
                                }
                              }
                            }
                          }
                        }
                      }
                      body1 {
                        ... on PrismicProcedureDataBody1Simple {
                          id
                          primary {
                            content {
                              text
                            }
                          }
                        }
                        ... on PrismicProcedureDataBody1RepeatableImageType {
                          id
                          primary {
                            content {
                              text
                            }
                          }
                        }
                      }               
                      title {
                        text
                      }
                    }
                  }


                  ... on PrismicNews {
                    id
                    type
                    uid
                    first_publication_date
                    data {
                      excerpt {
                        text
                      }
                      image {
                        gatsbyImageData(layout: CONSTRAINED, width: 600)
                      }
                      title {
                        text
                      }
                    }
                  }


                  ... on PrismicContactLocation {
                    id
                    type
                    uid
                    lang
                    data {
                      title {
                        text
                      }
                      long_title {
                        text
                      }
                      description {
                        html
                      }
                      virtual_tour {
                        embed_url
                      }
                      city {
                        text
                      }
                      address {
                        html
                        text
                      }
                      coordinates {
                        latitude
                        longitude
                      }                      
                      coordinates {
                        latitude
                        longitude
                      }                                         
                      gallery {
                        image {
                          alt
                          gatsbyImageData(layout: FULL_WIDTH)
                        }
                      }
                    }
                  }



                  ... on PrismicCondition {
                    id
                    uid
                    type
                    data {
                      title {
                        text
                      }
                      excerpt {
                        text
                      }
                      image {
                        gatsbyImageData(layout: CONSTRAINED, width: 600)
                      }
                    }
                  }



                  ... on PrismicTeamMember {
                    id
                    lang
                    data {
                      name {
                        text
                      }
                      specialisations {
                        specialisation: specialization {
                          document {
                            ... on PrismicSpecialisation {
                              id
                              uid
                              data {
                                title {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                      profile_image {
                        alt
                        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.5)
                        url
                      }
                    }
                    uid
                    type
                  }
                }
              }
            }
          }








        }
      }
    }
  }
`

StaticPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    staticPage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
