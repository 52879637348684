import React, { useContext, useRef, useState } from "react"

import PropTypes from "prop-types"

import makeStyles from "@material-ui/core/styles/makeStyles"
import { isWidthUp } from "@material-ui/core/withWidth"
import clsx from "clsx"

import Typography from "@material-ui/core/Typography"

import Grid from "@material-ui/core/Grid"
import axios from "axios"
import * as qs from "query-string"
import LangContext from "../../contexts/LangContext"
import CustomTextField from "../form/CustomTextField"
import CustomButton from "../buttons/CustomButton"

import useWidth from "../../services/useWidth"
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
  },
  lowerForm: {
    background: "#f7941d",
    padding: theme.spacing(3),
  },
  field: {
    borderRadius: 0,
    background: theme.palette.common.white,
  },
  infoText: {
    color: theme.palette.common.white,
    fontSize: 12,
    textAlign: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  sendButton: {
    marginTop: theme.spacing(1),
    boxShadow: "none",
    borderRadius: 0,
    textTransform: "initial",
    fontSize: 14,
  },
  successMessage: {
    fontSize: 18,
    textAlign: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const Survey = ({
  formName,
  location,
  disclaimerMessage,
  additionalData = {},
  ...props
}) => {
  const classes = useStyles()
  const width = useWidth()

  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const [surveyData, setSurveyData] = useState({
    'Competența echipei medicale': '',
    'Experiența echipei medicale': '',
    'Aparatură de ultimă generație': '',
    'Abordare integrată': '',
    'Spital multidisciplinar': '',
    'Abordare cazuistică complexă': '',
    'Cunoașteți direct medicii și experiența acestora': '',
    'Grijă față de pacient': '',
    'Recomandările suplimentare de la alți medici': '',
    'Reputația spitalului': '',
    'Reputația medicilor': '',
    'Decontări CAS / PNS': '',
    'Colaborarea de lungă durată cu spitalul respectiv': '',
  });

  const formNameRef = useRef(null)
  const sourceRef = useRef(null)
  const nameRef = useRef(null)
  const emailRef = useRef(null)
  const phoneRef = useRef(null)
  const specializationRef = useRef(null)
  const stateInstitutionRef = useRef(null)

  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const { className } = props

  if (!disclaimerMessage || disclaimerMessage.length <= 0) {
    disclaimerMessage = i18n.translations.contactDisclaimer
  }

  const handleSubmit = event => {
    event.preventDefault()

    setLoading(true)

    const formData = {
      "form-name": formNameRef.current.value,
      source: sourceRef.current.value,
      name: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      specialization: specializationRef.current.value,
      stateInstitution: stateInstitutionRef.current.value,

      ...surveyData,
      ...additionalData
    }

    const axiosOptions = {
      url: location.pathname,
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify(formData),
    }

    axios(axiosOptions)
      .then(response => {
        setSubmitted(true)
        setLoading(false)
        // window.history.pushState("", "", `${location.pathname}multumim/`)
      })
      .catch(err => {
        console.log(err)
        setSubmitted(false)
        setLoading(false)
      })
  }

  let dense = isWidthUp("sm", width)

  return (
    <div className={clsx(classes.root, className)}>
      {submitted && (
        <Typography className={classes.successMessage}>
          Multumim pentru răspunsuri
        </Typography>
      )}
      {!submitted && (
        <>
          <form
            name={formName}
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={event => handleSubmit(event)}
          >
            <input
              type="hidden"
              name="form-name"
              value={formName}
              ref={formNameRef}
            />

            <input
              type="hidden"
              name="source"
              value={location.href}
              ref={sourceRef}
            />

            {Object.keys(surveyData).map(key => (
              <input
                type="hidden"
                name={key}
                key={`survey_data_${key}`}
                value={surveyData[key]}
              />
            ))}

            {Object.keys(additionalData).map(key => (
              <input
                type="hidden"
                name={key}
                key={`add_data_${key}`}
                value={additionalData[key]}
              />
            ))}

            <p className="hidden">
              <label htmlFor="bot-field">
                Don’t fill this out if you're human:
                <input id="bot-field" name="bot-field" />
              </label>
            </p>
            <br />
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">Ce criterii considerați că sunt cele mai importante în alegerea unui spital privat pentru tratamentul dumneavoastră, al familiei și al pacienților dumneavoastră?</Typography>

                <br />
                <Typography variant="body1">Vă rugăm completați de la 1 la 10 pentru fiecare criteriu (10 fiind cel mai important și 1 fiind cel mai puțin important criteriu)</Typography>

                <br />
                <Grid container spacing={2}>
                  {Object.keys(surveyData).map((key) => (
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend"><b>{key}</b></FormLabel>
                        <RadioGroup row name="comp"
                          onChange={(e) => setSurveyData({
                            ...surveyData,
                            [key]: e.target.value
                          })}>
                          {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map((value) => (
                            <FormControlLabel
                              key={`competenta-${value}`}
                              value={value}
                              control={<Radio />}
                              label={value}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  ))}
                </Grid>




              </Grid>
            </Grid>

            <br />

            <div className={classes.lowerForm}>

              <Grid container>
                <Grid item xs={12}>
                  <CustomTextField
                    fullWidth
                    type="text"
                    id="specializationInput"
                    label="Specializarea dumneavoastră"
                    name="specialization"
                    inputRef={specializationRef}
                    disabled={loading}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <CustomTextField
                    fullWidth
                    type="text"
                    id="stateInstitutionInput"
                    label="Instituția de stat"
                    name="stateInstitution"
                    inputRef={stateInstitutionRef}
                    disabled={loading}
                    required
                  />
                </Grid>
              </Grid>

              <Grid container spacing={dense ? 2 : 0}>
                <Grid item xs={dense ? 4 : 12}>
                  <CustomTextField
                    fullWidth
                    type="text"
                    id="nameInput"
                    label={i18n.translations.contactName}
                    name="name"
                    inputRef={nameRef}
                    disabled={loading}
                    required
                  />
                </Grid>
                <Grid item xs={dense ? 4 : 12}>
                  <CustomTextField
                    fullWidth
                    type="email"
                    id="emailInput"
                    label={i18n.translations.contactEmail}
                    name="email"
                    inputRef={emailRef}
                    disabled={loading}
                  />
                </Grid>
                <Grid item xs={dense ? 4 : 12}>
                  <CustomTextField
                    fullWidth
                    type="tel"
                    pattern="[0-9\/|+\-\s]+"
                    id="phoneInput"
                    label={i18n.translations.contactPhone}
                    name="phone"
                    inputRef={phoneRef}
                    disabled={loading}
                    required
                  />
                </Grid>

                {/* 
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputRef={newsletterRef}
                      icon={<CheckBoxOutlineBlankRoundedIcon style={{ fontSize: 15, color: "#fff" }} />}
                      checkedIcon={<CheckBoxRoundedIcon style={{ fontSize: 15, color: "#fff" }} />}
                    />
                  }
                  label={<Typography style={{ color: "#fff", fontSize: 12 }}>
                    Sunt de acord să primesc Newsletter din partea ARES
                  </Typography>}
                />
              </Grid>
              */}
              </Grid>

              <Typography
                className={classes.infoText}
                dangerouslySetInnerHTML={{
                  __html: disclaimerMessage,
                }}
              />

              <CustomButton
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                className={classes.sendButton}
                disabled={loading}
              >
                {i18n.translations.sendMessage}
              </CustomButton>
            </div>
          </form>
        </>
      )}
    </div>
  )
}

export default Survey

Survey.defaultProps = {
  formName: 'Survey',
  disclaimerMessage: "",
}

Survey.propTypes = {
  formName: PropTypes.string,
  location: PropTypes.object.isRequired,
  locale: PropTypes.string,
  disclaimerMessage: PropTypes.string,
}
